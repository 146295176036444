<template>
  <div id="app" class="result">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <div class="spinner" v-if="!isOrderLoaded">
      <div><div></div></div>
    </div>
    <!-- <Spinner :spin="!isOrderLoaded" /> -->
    <!-- Header -->
    <section class="head">
      <div class="bg"></div>
      <span> {{ order.storeName }} </span>
    </section>

    <!-- Main -->
    <section class="main" v-if="isOrderLoaded">
      <section class="detail">
        <!-- 結果 -->
        <div class="top">
          <div class="icon">
            <!-- <div
              v-if="!order.paymentMethod && paymentStatus === 'done'"
              class="blue"
            >
              <img :src="pointLogo" />
            </div> -->
            <font-awesome-icon
              v-if="paymentStatus === 'done'"
              icon="fa-regular fa-circle-check"
              size="5x"
            />
            <div v-else-if="paymentStatus === 'issued'" class="orange">
              <font-awesome-icon
                icon="fa-solid fa-hourglass-half"
                class="fa-hourglass-half"
                size="2x"
              />
            </div>
            <font-awesome-icon
              v-else
              icon="fa-regular fa-circle-xmark"
              size="5x"
            />
          </div>
          <!-- <span
            v-if="!order.paymentMethod && paymentStatus === 'done'"
            class="title orange"
            >點數已折抵</span
          > -->
          <!-- <span
            v-if="paymentStatus === 'done' && order.paymentMethod === 'offline'"
            class="title orange"
          > 
            點數已折抵
          </span> -->
          <span v-if="paymentStatus === 'done'" class="title green">
            支付成功
          </span>
          <span v-else-if="paymentStatus === 'issued'" class="title orange">
            支付中
          </span>
          <span v-else-if="paymentStatus === 'cancelled'" class="title orange">
            已取消
          </span>
          <span v-else class="title red">支付失敗</span>
          <span class="cost">NT${{ order.amount }}</span>
          <div v-if="order.paymentMethod && order.paymentMethod !== 'offline'">
            <img v-if="paymentIcon" :src="paymentIcon" />
            <font-awesome-icon v-else icon="fa-solid fa-credit-card" />
            <span>{{ order.amount - order.pointAmount }}</span>
            <span v-if="order.pointAmount">+</span>
            <img v-if="order.pointAmount" :src="pointLogo" />
            <span v-if="order.pointAmount">{{ order.pointAmount }}</span>
          </div>
          <span
            v-if="order.paymentMethod === 'offline' && paymentStatus === 'done'"
            class="strong"
          >
            請現場支付剩餘NT$ {{ order.amount - order.pointAmount }}
          </span>
        </div>
        <!-- 資訊 -->
        <div class="center">
          <div>
            <span>消費金額</span>
            <span>NT${{ order.amount }}</span>
          </div>
          <div>
            <span>點數支付</span>
            <img :src="pointLogo" />
            <span>{{ order.pointAmount || 0 }}點</span>
          </div>
          <div>
            <span>其他支付</span>
            <!-- <i class="fa-solid fa-money-bill-wave fa-md"></i>
                  <i class="fa-solid fa-credit-card fa-md"></i>
                  <img src="@/assets/icon/pLine.svg"> -->
            <font-awesome-icon
              v-if="order.paymentMethod === 'offline'"
              icon="fa-solid fa-money-bill-wave"
            />
            <img v-else-if="paymentIcon" :src="paymentIcon" />
            <font-awesome-icon v-else icon="fa-solid fa-credit-card" />
            <span v-if="order.paymentMethod === 'offline'">現金</span>
            <span>{{ order.amount - order.pointAmount || 0 }}元</span>
          </div>
          <div>
            <span>交易時間</span>
            <span>{{ tsToDatetime(order.createdAt) }}</span>
          </div>
          <div>
            <span>交易序號</span>
            <span>{{ order.orderId }}</span>
          </div>
        </div>
        <!-- 回饋 -->
        <div v-if="cardNo" class="bottom">
          <!-- <span class="title">本次消費回饋</span> -->
          <div style="text-align: center">
            <barcode
              :value="cardNo"
              format="code128"
              width="2"
              height="50px"
              displayValue="false"
              :margin="5"
            >
            </barcode>
            <span class="fS">{{ cardNo }}</span>
          </div>
        </div>

        <!-- 按鈕 -->
        <div class="buttons">
          <button v-if="false && userInfo.cardNo" @click.prevent="popBarcodeOpen">
            <font-awesome-icon icon="fa-solid fa-barcode" />
            <span>載具條碼</span>
          </button>
          <button @click.prevent="$router.push('/home')">
            <span>回首頁</span>
          </button>
        </div>
      </section>
    </section>

    <!-- 載具條碼popup -->
    <section id="popBarcode" class="pop barcode">
      <div class="popBody">
        <a class="close">
          <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
        </a>
        <span class="title">載具條碼</span>
        <div style="text-align: center;">
          <barcode
            :value="userInfo.cardNo"
            format="code128"
            width="2"
            height="80"
            displayValue="false"
            :margin="10"
          >
          </barcode>
          <span> {{ userInfo.cardNo }} </span>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { getPaymentImage } from '@/utils/utils';
import VueBarcode from 'vue-barcode';
// import Spinner from '@/components/Spinner';

export default {
  name: '',
  components: {
    // Spinner,
    barcode: VueBarcode,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      order: {},
      orderId: this.$route.query.orderId,
      retry: 0,
      maxRetry: 30,
      retryInterval: 2000,
      isOrderLoaded: false,
      timeoutId: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (!to.query.orderId) {
        console.error('no order ID');
        next('/consumption-error');
      }
      if (that.user && that.user.userId) {
        // that
        //   .getOrderInfoById(to.query.orderId)
        //   .then((getOrderInfoByIdRes) => {
        //     console.log(
        //       'getOrderInfoByIdRes:' + JSON.stringify(getOrderInfoByIdRes)
        //     );
        //     that.order = { ...getOrderInfoByIdRes.data };
        //   })
        //   .catch((err) => {
        //     console.error('failed to get order info by id', err);
        //     next('/payment-error');
        //   });
      } else {
        next('/error');
      }
    });
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  created() {
    this.getUserPoints()
      .then((res) => {
        this.$store.commit('updateUserPoints', res);
        this.points = JSON.parse(JSON.stringify(this.userPoints));
      })
      .catch((err) => {
        console.log('err: ', err);
      });
    // this.getUserData();
  },
  mounted() {
    console.log('mounted!!!!');
    console.log(this.$route);
    // console.log(this.$route.query.points);
    // this.consumptionPoints = this.$route.query.points;
    this.pollingOrderState();
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapGetters({
      userPoints: 'payment/getUserPoints',
      shopSupportedPoints: 'payment/getShopPoints',
      availablePointTypes: 'payment/getAvailablePointTypes',
      paymentMethods: 'payment/getSupportedPaymentMethods',
    }),
    storeCode() {
      return this.order?.context?.storeCode || null;
    },
    paymentStatus() {
      return this.order?.status === 'issued'
        ? 'issued'
        : this.order?.status === 'completed' || this.order?.status === 'done'
        ? 'done'
        : this.order?.status === 'cancelled'
        ? 'cancelled'
        : 'failed';
    },
    pointLogo() {
      let point = null;
      if (this.order?.pointCode) {
        point = this.availablePointTypes.find((item) => {
          return String(item.pointCode) === String(this.order.pointCode);
        });
      }
      return point?.pointLogo;
    },
    paymentIcon() {
      if (this.order?.paymentService) {
        return getPaymentImage(
          this.order.paymentService,
          this.order.paymentType
        );
      } else {
        return '';
      }
    },
    cardNo() {
      return this.userInfo?.cardNo ? this.userInfo.cardNo.trim(): null;
    }
  },
  watch: {
    storeCode: {
      handler(newVal) {
        if (newVal) {
          this.getAppointedStoreInfo();
        }
      },
    },
  },
  methods: {
    tsToDatetime(ts) {
      return ts ? moment.unix(ts).format('YYYY-MM-DD HH:mm:ss') : '';
    },
    tsToDate(ts) {
      return ts ? moment.unix(ts).format('YYYY-MM-DD') : '';
    },
    // callUserDataAPI() {
    //   var config = {
    //     method: 'get',
    //     url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
    //     headers: {},
    //   };

    //   return this.$http(config)
    //     .then(function (response) {
    //       console.log(JSON.stringify(response.data));
    //       return response;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    // getUserData() {
    //   this.callUserDataAPI()
    //     // this.dummyUserData()
    //     .then((res) => {
    //       this.userData = res.data;
    //       this.originalCardNo = res.data.cardNo ? res.data.cardNo : null;
    //     })
    //     .catch((err) => {
    //       console.log('err: ', err);
    //       this.$router.push('/error');
    //     });
    // },
    getAppointedStoreInfo() {
      console.log(this.storeCode);
      let that = this;
      var config = {
        method: 'get',
        url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/store-codes/${this.storeCode}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(
            'getAppointedStoreInfoRes' + JSON.stringify(response.data)
          );
          that.$store.commit(
            'payment/updateSupportedPointTypes',
            response.data.points
          );
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUserPoints() {
      console.log('this.userId: ', this.user.userId);
      let that = this;
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          that.$store.commit('payment/updateUserPoints', response.data);
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getOrderInfoById(orderId) {
      var config = {
        method: 'get',
        // url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/orders/${orderId}/info`,
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/members/${this.user.userId}/orders/${orderId}`,
        headers: {},
      };

      return this.$http(config);
    },
    pollingOrderState() {
      console.log('pollingOrderState', this.orderId);
      this.getOrderInfoById(this.orderId)
        .then((getOrderInfoByIdRes) => {
          console.log(
            'getOrderInfoByIdRes:' + JSON.stringify(getOrderInfoByIdRes)
          );
          this.isOrderLoaded = true;
          this.order = { ...getOrderInfoByIdRes.data };
          if (this.order.status === 'issued' && this.retry < this.maxRetry) {
            this.retry++;
            console.log(`retry after ${this.retryInterval} ms`);
            this.timeoutId = setTimeout(
              this.pollingOrderState,
              this.retryInterval
            );
          } else {
            this.retry = 0;
            this.timeoutId = null;
          }
        })
        .catch((err) => {
          console.error('failed to get order info by id', err);
          // this.$router.push('/payment-error');
          if (err.response?.data?.error?.code === 'OR003') {
            this.$router.push({
              path: '/consumption-error',
              query: { code: err.response?.data?.error?.code },
            });
          } else {
            if (this.retry < 5) {
              this.retry++;
              console.log(`retry after ${this.retryInterval} ms`);
              this.timeoutId = setTimeout(this.pollingOrderState, 1000);
            } else {
              this.isOrderLoaded = true;
              this.timeoutId = null;
              this.$router.push('/consumption-error');
            }
          }
        });
    },
    popBarcodeOpen() {
      var popBarcode = document.getElementById('popBarcode');
      var close = popBarcode.getElementsByClassName('close')[0];
      close.onclick = function () {
        popBarcode.style.display = 'none';
      };
      window.onclick = function (event) {
        if (event.target == popBarcode) {
          popBarcode.style.display = 'none';
        }
      };
      popBarcode.style.display = 'flex';
    },
  },
};
</script>
<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
